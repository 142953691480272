<template>
    <div class="page-content w-100">
  <!-- start heading -->
  <div class="row">
    <div class="col-sm-12 mb-3">
      <p class="text-center page-heading">{{ clinicianOthersPayload?.customer?.companyShortName }}</p>
    </div>
  </div>
  <div class="mobie-setting-mainDiv" v-for="item in manage" :key="item">
        <!-- <router-link to="/clinicianothers/manage/team" > -->
          <div class="SettingName" style="color: #000; cursor: auto;" v-if="item.name == 'Team'">Team</div>
        <!-- </router-link> -->
        <router-link to="/clinicianothers/manage/partner-clinics" v-if="item.name == 'Partner clinic'">
            <div  class="SettingName">Partner clinics</div>
        </router-link>
        <router-link to="/clinicianothers/manage/inventory" v-if="item.name === 'Inventory' && (doctorData?.customer?.companyShortName === 'FootSecure' || doctorData?.customer?.companyShortName === 'Foot Secure')" >
            <div  class="SettingName">Inventory</div>
        </router-link>
        <router-link  v-else-if=" item.name == 'Inventory'" >
            <div  class="SettingName" style="color: #000; cursor: auto;">Inventory</div>
        </router-link>
        <router-link to="/clinicianothers/messages" v-if="item.name == 'Orders'">
            <div class="SettingName">Orders</div>
        </router-link>
        <router-link to="/clinicianothers/messages" v-if="item.name == 'Library'">
            <div class="SettingName">Library</div>
        </router-link>
      </div>
  
</div>
<clinicianotherfooter></clinicianotherfooter>
</template>

<script>
import clinicianotherfooter from "./clinician-others-footer.vue";
import axios from 'axios';
export default {
components: {
    clinicianotherfooter
},
data() {
    return {
        clinicianOthersPayload:{},
        manage:[]
    }
},
created: function () {
    let clinicianothers = localStorage.getItem('clinician-others-id')
    this.clinicianOthersID = JSON.parse(clinicianothers);
    this.getClinicianOthers()
},

computed: {
},

methods: {
    getClinicianOthers(){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
     axios
    .get(`${BASE_API_URL}/clinician-others/${this.clinicianOthersID}/one`)
    .then((response) => {
        this.clinicianOthersPayload=response.data
        this.manage=this.clinicianOthersPayload.customer.manage
        console.log(this.clinicianOthersPayload,"this.clinicianOthersPayload");
        console.log(this.manage,"this.manage");
    })
},
 
}
};
</script>
<style>
.SettingName {
    font-size: 19px;
    color: #4872e9;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }
  
  .mobie-setting-mainDiv {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
</style>